import { useAuthenticated, useInitialized } from '../../store/hooks';
import { useDispatch } from 'react-redux';
import { initialize, loadData } from '../../store/actions';
import { useEffect } from 'react';
import Initialization from '../Initialization';
import { BrowserRouter as Router } from 'react-router-dom';
import UnauthenticatedRoutes from '../routes/UnauthenticatedRoutes';
import AuthenticatedRoutes from '../routes/AuthenticatedRoutes';

const Main = () => {
  const initialized = useInitialized();
  const authenticated = useAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialized) {
      return;
    }
    dispatch(initialize());
  }, [dispatch, initialized]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    dispatch(loadData());
  }, [dispatch, authenticated]);

  if (!initialized) {
    return (
      <Initialization/>
    );
  }

  return (
    <Router>
      {!authenticated && <UnauthenticatedRoutes/>}
      {authenticated && <AuthenticatedRoutes/>}
    </Router>
  );
};

export default Main;
