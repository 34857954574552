import { combineReducers } from 'redux';
import makeCollectionReducer from './collection';
import { ITEMS_COLLECTION, SITES_COLLECTION } from '../../collections';

const dataReducer = combineReducers({
  sites: makeCollectionReducer(SITES_COLLECTION),
  items: makeCollectionReducer(ITEMS_COLLECTION),
});

export default dataReducer;
