const storage = localStorage;
const tokenKey = 'token';

export const tokenFromCookie = () => {
   return document.cookie.replace(/(?:(?:^|.*;\s*)ftk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
};

export const tokenFromStorage = () => {
  return storage.getItem(tokenKey);
};

export const tokenToStorage = token => {
  storage.setItem(tokenKey, token);
};

export const clearStorage = () => {
  document.cookie = 'ftk=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  storage.clear();
};
