import {
  DATA_EDIT,
  DATA_FAIL,
  DATA_FORM_CHANGE,
  DATA_FORM_DATA_CHANGE,
  DATA_FORM_DATA_COLLECTION_ADD,
  DATA_FORM_DATA_COLLECTION_REMOVE,
  DATA_FORM_DISABLE,
  DATA_FORM_ENABLE,
  DATA_REMOVE_FAIL,
  DATA_REMOVE_REQUEST,
  DATA_REMOVE_SUCCESS,
  DATA_REQUEST, DATA_SAVE_COMPLETE,
  DATA_SAVE_FAIL,
  DATA_SAVE_REQUEST,
  DATA_SAVE_SUCCESS,
  DATA_SILENT_SUCCESS,
  DATA_SUCCESS,
  DATA_UPDATE_FAIL,
  DATA_UPDATE_REQUEST,
  DATA_UPDATE_SUCCESS
} from '../../actions';

const makeCollectionReducer = collection => {
  const listEmpty = {
    items: [],
    loading: false,
    error: null,
  };

  const formEmpty = {
    item: undefined,
    disabled: false,
    loading: false,
    error: null,
    saved: false,
  };

  const initialState = {
    list: listEmpty,
    form: formEmpty,
  };

  const collectionReducer = (state = initialState, action) => {
    switch (action.type) {
      case DATA_REQUEST:
        return reduceRequest(state, action);
      case DATA_SUCCESS:
        return reduceSuccess(state, action);
      case DATA_SILENT_SUCCESS:
        return reduceSilentSuccess(state, action);
      case DATA_FAIL:
        return reduceFail(state, action);
      case DATA_EDIT:
        return reduceEdit(state, action);
      case DATA_FORM_CHANGE:
        return reduceFormChange(state, action);
      case DATA_FORM_DATA_CHANGE:
        return reduceFormDataChange(state, action);
      case DATA_FORM_DATA_COLLECTION_ADD:
        return reduceFormDataCollectionAdd(state, action);
      case DATA_FORM_DATA_COLLECTION_REMOVE:
        return reduceFormDataCollectionRemove(state, action);
      case DATA_FORM_DISABLE:
        return reduceFormDisable(state, action);
      case DATA_FORM_ENABLE:
        return reduceFormEnable(state, action);
      case DATA_SAVE_REQUEST:
        return reduceSaveRequest(state, action);
      case DATA_SAVE_SUCCESS:
        return reduceSaveSuccess(state, action);
      case DATA_SAVE_FAIL:
        return reduceSaveFail(state, action);
      case DATA_SAVE_COMPLETE:
        return reduceSaveComplete(state, action);
      case DATA_UPDATE_REQUEST:
        return reduceUpdateRequest(state, action);
      case DATA_UPDATE_SUCCESS:
        return reduceUpdateSuccess(state, action);
      case DATA_UPDATE_FAIL:
        return reduceUpdateFail(state, action);
      case DATA_REMOVE_REQUEST:
        return reduceRemoveRequest(state, action);
      case DATA_REMOVE_SUCCESS:
        return reduceRemoveSuccess(state, action);
      case DATA_REMOVE_FAIL:
        return reduceRemoveFail(state, action);
      default:
        return state;
    }
  };

  const reduceRequest = (state, action) => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: true,
        error: null,
      },
    };
  };

  const reduceSuccess = (state, action) => {
    const items = action.payload[collection];
    return {
      ...state,
      list: {
        ...state.list,
        items,
        loading: false,
        error: null,
      },
    };
  };

  const reduceSilentSuccess = (state, action) => {
    const items = action.payload[collection];
    return {
      ...state,
      list: {
        ...state.list,
        items,
      },
    };
  };

  const reduceFail = (state, action) => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: action.payload.error,
      },
    };
  };

  const reduceEdit = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...formEmpty,
        item: {
          isNew: false, ...(state.list.items.find(o => o.id === action.payload.id) || {
            id: action.payload.id,
            isNew: true
          })
        },
      }
    };
  };

  const reduceFormChange = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {property, value} = action.payload;

    return {
      ...state,
      form: {
        ...state.form,
        item: {
          ...state.form.item,
          [property]: value,
        },
      }
    };
  };

  const reduceFormDataChange = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {property, value} = action.payload;

    return {
      ...state,
      form: {
        ...state.form,
        item: {
          ...state.form.item,
          data: {
            ...state.form.item.data,
            [property]: value,
          },
        },
      },
    };
  };

  const reduceFormDataCollectionAdd = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {property, value} = action.payload;

    return {
      ...state,
      form: {
        ...state.form,
        item: {
          ...state.form.item,
          data: {
            ...state.form.item.data,
            [property]: [...(Array.isArray(state.form.item.data[property]) ? state.form.item.data[property] : []), value],
          },
        },
      },
    };
  };

  const reduceFormDataCollectionRemove = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {property, value} = action.payload;

    return {
      ...state,
      form: {
        ...state.form,
        item: {
          ...state.form.item,
          data: {
            ...state.form.item.data,
            [property]: (Array.isArray(state.form.item.data[property]) ? state.form.item.data[property].filter(o => o !== value) : []),
          },
        },
      },
    };
  };

  const reduceFormDisable = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...state.form,
        disabled: true,
      },
    };
  };

  const reduceFormEnable = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...state.form,
        disabled: false,
      },
    };
  };

  const reduceSaveRequest = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...state.form,
        loading: true,
        error: null,
      },
    };
  };

  const reduceSaveSuccess = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {item} = action.payload;

    return {
      ...state,
      list: {
        ...state.list,
        items: state.list.items.find(o => o.id === item.id) ? state.list.items.map(o => {
          if (o.id !== item.id) {
            return o;
          }
          return item;
        }) : [...state.list.items, item],
        loading: false,
        error: null,
      },
      form: {
        ...formEmpty,
        saved: true,
        item,
      },
    };
  };

  const reduceSaveComplete = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...formEmpty,
      },
    };
  };

  const reduceSaveFail = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      form: {
        ...state.form,
        loading: false,
        error: action.payload.error,
      },
    };
  };

  const reduceUpdateRequest = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      list: {
        ...state.list,
        loading: true,
        error: null,
      },
    };
  };

  const reduceUpdateSuccess = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    const {item} = action.payload;

    return {
      ...state,
      list: {
        ...state.list,
        items: state.list.items.map(o => {
          if (o.id !== item.id) {
            return o;
          }
          return item;
        }),
        loading: false,
        error: null,
      },
    };
  };

  const reduceUpdateFail = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: action.payload.error,
      },
    };
  };

  const reduceRemoveRequest = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      list: {
        ...state.list,
        loading: true,
        error: null,
      },
    };
  };

  const reduceRemoveSuccess = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: null,
      },
    };
  };

  const reduceRemoveFail = (state, action) => {
    if (action.payload.collection !== collection) {
      return state;
    }

    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: action.payload.error,
      },
    };
  };

  return collectionReducer;
};

export default makeCollectionReducer;
