import {
  AppBar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@abdt/ornament';
import { Error, Search } from '@abdt/icons';
import { LinearProgress, makeStyles, useMediaQuery } from '@material-ui/core';
import { useDashboard, useItems, useMe, useSites } from '../../store/hooks';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dashboardFilterChange, dataRemove } from '../../store/actions';
import routes from '../../routes';
import { useHistory } from 'react-router';
import SortingDirection from '../../components/SortingDirection';
import TextButton from '../../components/buttons/TextButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { SITES_COLLECTION } from '../../store/collections';
import Remove from '../../components/icons/Remove';
import Edit from '../../components/icons/Edit';
import Items from '../../components/icons/Items';
import Code from '../../components/icons/Code';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  block: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sortableHead: {
    userSelect: 'none',
    cursor: 'pointer',
  },
  toolbar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  items: {
    backgroundColor: '#f0f0f0',
  }
}));

const DashboardPage = (props) => {
  const classes = useStyles();
  const match = useMediaQuery(theme => theme.breakpoints.up('md'));
  const history = useHistory();

  const me = useMe();
  const dashboard = useDashboard();

  const sites = useSites();
  const dispatch = useDispatch();

  const handleFilterChange = evt => {
    const {value} = evt.target;
    dispatch(dashboardFilterChange(value.trim().toLowerCase()));
  };

  const [sortingField, setSortingField] = useState('domain');
  const [sortingReverse, setSortingReverse] = useState(false);

  const toggleSort = field => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse(prevState => !prevState);
    }
  };

  const [removeSiteId, setRemoveSiteId] = useState(null);
  const [removeSiteModalOpen, setRemoveSiteModalOpen] = useState(false);

  const handleAddSite = evt => {
    evt.preventDefault();
    history.push(routes.siteEdit.make('00000000-00000000-00000000-00000000'));
  }

  const handleShowItems = id => {
    history.push(routes.items.make(id));
  };

  const handleEditSite = id => {
    history.push(routes.siteEdit.make(id));
  }

  const handleCopySiteCode = id => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(`
        <div data-id="faq">
          <link rel="stylesheet" href="${process.env.REACT_APP_MEDIA_URL}/style.min.css">
          <script src="${process.env.REACT_APP_SCRIPT_URL}/${id}.js" defer></script>
          <noscript>Для отображения FAQ необходимо включить JavaScript</noscript>
          <div data-id="faq-container"></div>
        </div>
      `);
    }
  };

  const handleRemoveSiteModalShow = id => {
    setRemoveSiteId(id);
    setRemoveSiteModalOpen(true);
  };

  const handleRemoveSiteModalClose = () => {
    setRemoveSiteId(null);
    setRemoveSiteModalOpen(false);
  };

  const handleRemoveSite = () => {
    dispatch(dataRemove(removeSiteId, SITES_COLLECTION));
    setRemoveSiteId(null);
    setRemoveSiteModalOpen(false);
  };

  const [expanded, setExpanded] = useState(null);

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={12} lg={12} xs={12}>
              <TextField label="Фильтр по сайтам" name="search" fullWidth
                         value={dashboard.filter}
                         onChange={handleFilterChange}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <Search/>
                             </InputAdornment>
                           ),
                         }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="main" className={classes.content}>
        <Box visibility={sites.loading ? 'visible' : 'hidden'}>
          <LinearProgress/>
        </Box>
        <Box visibility={sites.error ? 'visible' : 'hidden'}>
          <Error error={sites.error}/>
        </Box>
        {!sites.loading && <>
          {sites.items.length === 0 && <>
            <Typography variant="h1">
              Начните с добавления нового сайта
            </Typography>
            <Box className={classes.toolbar}>
              <Typography variant="body1">
                <Button disableElevation color="primary" onClick={handleAddSite}>Добавить сайт</Button>
              </Typography>
            </Box>
          </>}
          {sites.items.length !== 0 && <>
            <Typography variant="h1">
              Ваши сайты&nbsp;<Button disableElevation color="primary" onClick={handleAddSite}>Добавить сайт</Button>
            </Typography>
            <Box className={classes.toolbar}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.sortableHead} style={{width: '80%'}}
                                 onClick={() => toggleSort('domain')}>
                        Домен {sortingField === 'domain' && <SortingDirection reverse={sortingReverse}/>}
                      </TableCell>
                      <TableCell style={{width: '20%'}}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sites.items
                      .sort((a, b) => {
                        let result = 0;
                        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
                          result = a[sortingField].localeCompare(b[sortingField]);
                        } else {
                          result = a[sortingField] - b[sortingField];
                        }

                        return sortingReverse ? -result : result;
                      })
                      .filter(o => o.data?.domain?.toLowerCase()?.trim()?.includes(dashboard.filter?.toLowerCase()?.trim()))
                      .map((o, idx) => <Fragment key={o.id}>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body1">{o.data?.domain}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton title="Код для вставки" onClick={() => handleCopySiteCode(o.id)}>
                              <Code/>
                            </IconButton>
                            <IconButton title="Элементы FAQ" onClick={() => handleShowItems(o.id)}>
                              <Items/>
                            </IconButton>
                            <IconButton title="Редактировать сайт" onClick={() => handleEditSite(o.id)}>
                              <Edit/>
                            </IconButton>
                            <IconButton title="Удалить" onClick={() => handleRemoveSiteModalShow(o.id)}>
                              <Remove/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>}
        </>}
      </Box>
      <Dialog
        open={removeSiteModalOpen}
        onClose={handleRemoveSiteModalClose}
      >
        <DialogTitle>Удалить</DialogTitle>
        <DialogContent>
          <Box width="320">
            <Typography variant="body1" component="p">
              Вы уверены, что хотите удалить сайт?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <TextButton onClick={handleRemoveSiteModalClose} type="button">Отменить</TextButton>
          <PrimaryButton onClick={handleRemoveSite}>Удалить</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardPage;
