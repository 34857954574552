import { combineReducers } from 'redux';
import { INITIALIZED, RESET } from '../actions';
import authReducer from './auth';
import dataReducer from './data/';
import dashboardReducer from './dashboard';

const initializeReducer = (state = false, action) => {
  switch (action.type) {
    case INITIALIZED:
      return true;
    default:
      return state;
  }
}

const appReducer = combineReducers({
  initialized: initializeReducer,
  auth: authReducer,
  data: dataReducer,
  dashboard: dashboardReducer,
});

const reducer = (state, action) => {
  switch (action.type) {
    case RESET:
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
};

export default reducer;
