import PropTypes from 'prop-types';

const SortingDirection = ({reverse}) => {
  return (
    <>
      {!reverse && <>↓</>}
      {reverse && <>↑</>}
    </>
  );
};

SortingDirection.propTypes = {
  reverse: PropTypes.bool,
};

export default SortingDirection;
