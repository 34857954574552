import axios from 'axios';
import { logout } from '../store/actions';
import store from '../store';
import { tokenSelector } from '../store/hooks';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use(value => {
  const token = tokenSelector(store.getState());
  if (token) {
    value.headers['Authorization'] = token;
  }
  return value;
}, error => Promise.reject(error));

client.interceptors.response.use(value => value, error => {
  if (error?.response?.status === 401) {
    store.dispatch(logout());
  }

  return Promise.reject(error);
});

export default client;
