import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@abdt/ornament';
import { Error } from '@abdt/icons';
import { LinearProgress, makeStyles, useMediaQuery } from '@material-ui/core';
import { useItems, useMe, useSite } from '../../store/hooks';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataRemove } from '../../store/actions';
import routes from '../../routes';
import { useHistory, useParams } from 'react-router';
import SortingDirection from '../../components/SortingDirection';
import TextButton from '../../components/buttons/TextButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { ITEMS_COLLECTION } from '../../store/collections';
import Remove from '../../components/icons/Remove';
import Edit from '../../components/icons/Edit';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  block: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sortableHead: {
    userSelect: 'none',
    cursor: 'pointer',
  },
  toolbar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  items: {
    backgroundColor: '#f0f0f0',
  }
}));

const ItemsPage = (props) => {
  const classes = useStyles();
  const match = useMediaQuery(theme => theme.breakpoints.up('md'));
  const history = useHistory();
  const {siteId} = useParams();
  const me = useMe();

  const allItems = useItems();
  const [site] = useSite(siteId);
  const items = useMemo(() => {
    return {
      ...allItems,
      items: allItems?.items?.filter(o => o?.data?.siteId === siteId) ?? []
    };
  }, [siteId, allItems]);
  const dispatch = useDispatch();

  const handleBack = () => {
    history.goBack();
  };

  const [sortingField, setSortingField] = useState('domain');
  const [sortingReverse, setSortingReverse] = useState(false);

  const toggleSort = field => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse(prevState => !prevState);
    }
  };

  const [removeItemId, setRemoveItemId] = useState(null);
  const [removeItemModalOpen, setRemoveItemModalOpen] = useState(false);

  const handleAddItem = evt => {
    evt.preventDefault();
    history.push(routes.itemEdit.make(siteId, '00000000-00000000-00000000-00000000'));
  }

  const handleEditItem = id => {
    history.push(routes.itemEdit.make(siteId, id));
  }

  const handleRemoveItemModalShow = id => {
    setRemoveItemId(id);
    setRemoveItemModalOpen(true);
  };

  const handleRemoveItemModalClose = () => {
    setRemoveItemId(null);
    setRemoveItemModalOpen(false);
  };

  const handleRemoveItem = () => {
    dispatch(dataRemove(removeItemId, ITEMS_COLLECTION));
    setRemoveItemId(null);
    setRemoveItemModalOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Grid item>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item>
                <IconButton onClick={handleBack} title="Back">
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h4" component="span">
                  {site?.data?.domain}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="main" className={classes.content}>
        <Box visibility={items.loading ? 'visible' : 'hidden'}>
          <LinearProgress/>
        </Box>
        <Box visibility={items.error ? 'visible' : 'hidden'}>
          <Error error={items.error}/>
        </Box>
        {!items.loading && <>
          {items.items.length === 0 && <>
            <Typography variant="h1">
              Начните с добавления нового вопроса
            </Typography>
            <Box className={classes.toolbar}>
              <Typography variant="body1">
                <Button disableElevation color="primary" onClick={handleAddItem}>Добавить вопрос</Button>
              </Typography>
            </Box>
          </>}
          {items.items.length !== 0 && <>
            <Typography variant="h1">
              Ваши вопросы&nbsp;<Button disableElevation color="primary" onClick={handleAddItem}>Добавить
              вопрос</Button>
            </Typography>
            <Box className={classes.toolbar}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.sortableHead} style={{width: '80%'}}
                                 onClick={() => toggleSort('data.name')}>
                        Вопрос {sortingField === 'data.name' && <SortingDirection reverse={sortingReverse}/>}
                      </TableCell>
                      <TableCell style={{width: '20%'}}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.items
                      .sort((a, b) => {
                        let result = 0;
                        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
                          result = a[sortingField].localeCompare(b[sortingField]);
                        } else {
                          result = a[sortingField] - b[sortingField];
                        }

                        return sortingReverse ? -result : result;
                      })
                      .map((o, idx) => <Fragment key={o.id}>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body1">{o.data?.name}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton title="Edit" onClick={() => handleEditItem(o.id)}>
                              <Edit/>
                            </IconButton>
                            <IconButton title="Удалить" onClick={() => handleRemoveItemModalShow(o.id)}>
                              <Remove/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>}
        </>}
      </Box>
      <Dialog
        open={removeItemModalOpen}
        onClose={handleRemoveItemModalClose}
      >
        <DialogTitle>Удалить</DialogTitle>
        <DialogContent>
          <Box width="320">
            <Typography variant="body1" component="p">
              Вы уверены, что хотите удалить вопрос?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <TextButton onClick={handleRemoveItemModalClose} type="button">Отменить</TextButton>
          <PrimaryButton onClick={handleRemoveItem}>Удалить</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemsPage;
