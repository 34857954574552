import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions';
import { Box, Drawer, Grid, Link, Typography } from '@abdt/ornament';
import { Backdrop, ClickAwayListener, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import closeIcon from './close.svg';
import openIcon from './open.svg';
import logoutIcon from './logout.svg';
import { useMe } from '../../store/hooks';
import PrimaryButton from '../buttons/PrimaryButton';
import { LogOut } from '@abdt/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import routes from '../../routes';

const sidebarOpenedWidth = 336;
const sidebarClosedWidth = 88;

const useStyles = makeStyles(theme => ({
  root: {
    width: sidebarOpenedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  backdrop: {
    zIndex: 0,
  },
  drawerOpen: {
    width: sidebarOpenedWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: sidebarClosedWidth,
    border: 'none',
  },
  paper: {
    backgroundColor: '#2A1C43',
    zIndex: 999,
  },
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  grid: {
    height: '100%',
    flexWrap: 'nowrap',
  },
  logo: {
    marginBottom: theme.spacing(5),
    display: 'block',
  },
  toggle: {
    display: 'block',
    cursor: 'pointer',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    color: '#FFFFFF',
  },
  bottom: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: '#FFFFFF',
  },
  organization: {
    marginTop: theme.spacing(5),
  },
  user: {
    marginTop: theme.spacing(3),
  },
  info: {
    marginTop: theme.spacing(2),
  },
  support: {},
  logout: {
    marginTop: theme.spacing(5),
    height: 62,
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  routerLink: {
    color: '#FFFFFF',
    display: 'block',
    padding: '5px 10px',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  link: {
    color: '#FFFFFF',
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useMe();

  const toggleDrawer = () => {
    setOpen(prevState => !prevState);
  };

  const closeDrawer = evt => {
    evt.stopPropagation();
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Backdrop open={open} className={classes.backdrop}/>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={closeDrawer}
      >
        <Drawer variant="permanent"
                className={clsx(classes.root, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                PaperProps={{
                  className: clsx(classes.paper, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  })
                }}
        >
          <Box className={classes.container}>
            <Grid container direction="column" justify="space-between" className={classes.grid}>
              <Box>
                <img src={open ? closeIcon : openIcon} alt="close" onClick={toggleDrawer} className={classes.toggle}/>
              </Box>
              <Box className={classes.content}>
                <Box visibility={open ? 'visible' : 'hidden'}>
                  <Box className={classes.organization}>
                    <Typography variant="h3" component="h3" color="inherit">
                      {me.parent?.name ?? me.name}
                    </Typography>
                  </Box>
                  <Box className={classes.info}>
                    <Typography variant="body1">
                      <Link to={routes.dashboard.make()} component={RouterLink} onClick={toggleDrawer} className={classes.routerLink}>
                        Дашборд
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.bottom}>
                <Box className={classes.logout}>
                  {open ? <PrimaryButton disableElevation fullWidth startIcon={<LogOut/>}
                                         onClick={handleLogout}>Выйти</PrimaryButton> :
                    <img src={logoutIcon} alt="Выйти" onClick={handleLogout}/>}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Drawer>
      </ClickAwayListener>
    </>
  );
};

export default Sidebar;
