import React from 'react';
import PropTypes from 'prop-types';
import icon from './icon.svg';

const Items = () => {
  return (
    <img src={icon}/>
  );
};

Items.propTypes = {
};

export default Items;
