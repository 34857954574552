import { ROLE_ANONYMOUS } from './roles';

const anonymous = Object.freeze({
  id: -1,
  login: 'anonymous',
  name: 'Anonymous',
  roles: Object.freeze([ROLE_ANONYMOUS]),
});

export default anonymous;
