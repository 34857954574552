import { combineReducers } from 'redux';
import tokenAndUserReducer from './tokenAndUser';
import loginReducer from './login';

const authReducer = combineReducers({
    tokenAndUser: tokenAndUserReducer,
    login: loginReducer,
  },
);

export default authReducer;
