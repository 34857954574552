export const INITIALIZED = 'INITIALIZED';
export const AUTHENTICATE = 'AUTHENTICATE';
export const RESET = 'RESET';

export const LOGIN_FORM_CHANGE = 'LOGIN_FORM_CHANGE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const DATA_REQUEST = 'DATA_REQUEST';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_SILENT_SUCCESS = 'DATA_SILENT_SUCCESS';
export const DATA_FAIL = 'DATA_FAIL';

export const DATA_UPDATE_REQUEST = 'DATA_UPDATE_REQUEST';
export const DATA_UPDATE_SUCCESS = 'DATA_UPDATE_SUCCESS';
export const DATA_UPDATE_FAIL = 'DATA_UPDATE_FAIL';

export const DATA_EDIT = 'DATA_EDIT';
export const DATA_FORM_CHANGE = 'DATA_FORM_CHANGE';
export const DATA_FORM_DATA_CHANGE = 'DATA_FORM_DATA_CHANGE';
export const DATA_FORM_DATA_COLLECTION_ADD = 'DATA_FORM_DATA_COLLECTION_ADD';
export const DATA_FORM_DATA_COLLECTION_REMOVE = 'DATA_FORM_DATA_COLLECTION_REMOVE';
export const DATA_FORM_DISABLE = 'DATA_FORM_DISABLE';
export const DATA_FORM_ENABLE = 'DATA_FORM_ENABLE';
export const DATA_SAVE_REQUEST = 'DATA_SAVE_REQUEST';
export const DATA_SAVE_SUCCESS = 'DATA_SAVE_SUCCESS';
export const DATA_SAVE_COMPLETE = 'DATA_SAVE_COMPLETE';
export const DATA_SAVE_FAIL = 'DATA_SAVE_FAIL';

export const DATA_REMOVE_REQUEST = 'DATA_REMOVE_REQUEST';
export const DATA_REMOVE_SUCCESS = 'DATA_REMOVE_SUCCESS';
export const DATA_REMOVE_FAIL = 'DATA_REMOVE_FAIL';

export const DASHBOARD_FILTER_CHANGE = 'DASHBOARD_FILTER_CHANGE';
