import React from 'react';
import PropTypes from 'prop-types';
import icon from './icon.svg';

const Edit = () => {
  return (
    <img src={icon}/>
  );
};

Edit.propTypes = {
};

export default Edit;
