const byId = (pattern, expression, id) => pattern.replace(expression, id);

const routes = {
  root: {
    pattern: '/',
    make() {
      return this.pattern;
    },
  },
  signin: {
    pattern: '/signin',
    make() {
      return this.pattern;
    },
  },
  dashboard: {
    pattern: '/dashboard',
    make() {
      return this.pattern;
    },
  },
  siteEdit: {
    pattern: '/sites/:siteId',
    make(siteId) {
      return byId(this.pattern, ':siteId', siteId);
    },
  },
  items: {
    pattern: '/sites/:siteId/items',
    make(siteId) {
      return byId(this.pattern, ':siteId', siteId);
    },
  },
  itemEdit: {
    pattern: '/sites/:siteId/items/:itemId',
    make(siteId, itemId) {
      return byId(byId(this.pattern, ':siteId', siteId), ':itemId', itemId);
    },
  },
};

export default routes;
