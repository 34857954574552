import { useMemo } from 'react';
import { Typography } from '@abdt/ornament';
import PropTypes from 'prop-types';

const errors = {
  'err.network': 'Network error, check Internet connection',
  'err.unknown': 'Unknown error',
  'err.internal': 'Internal server error',
  'err.unauthenticated': 'Authentication required',
  'err.password.not_matches': 'Password not matches',
  'err.user.inactive': 'User blocked, contact administrator',
  'err.user.not_found': 'User not found, contact administrator',
  'err.user.password_invalid': 'Invalid password',
  'err.operation.forbidden': 'Данная операция запрещена текущему пользователю',
  'err.property.restricted': 'Свойство недоступно для изменения',
  'err.item.not_found': 'Item not found',
  'err.registration.too_many_attempts': 'Too many attempts, please wait 1 hour',
  'err.registration.email_already_registered': 'Email already registered',
  'err.registration.email_not_registered': 'Email not registered',
  'err.recovery.too_many_attempts': 'Too many attempts, please wait 1 hour',
  'err.validation.invalid_email': 'Invalid email',
  'err.validation.invalid_account_type': 'Invalid account type',
};

const translateError = error => {
  if (!error) {
    return null;
  }

  const message = typeof error === 'string' ? error : (error?.code ?? error?.response?.data?.error ?? (error.isAxiosError ? 'err.network' : 'err.unknown'));
  return errors[message] || 'Unknown error. Please try again later';
};

const Error = ({error}) => {
  const message = useMemo(() => translateError(error), [error]);

  return (
    <Typography color="error" variant="body1">
      {message}
    </Typography>
  );
};

Error.propTypes = {
  errors: PropTypes.any,
};

export default Error;
