import React from 'react';
import PropTypes from 'prop-types';
import icon from './icon.svg';

const Code = () => {
  return (
    <img src={icon}/>
  );
};

Code.propTypes = {
};

export default Code;
