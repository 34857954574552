import { LinearProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import noop from '../../utils/noop';
import { Box } from '@abdt/ornament';
import { forwardRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  progress: {
    marginBottom: theme.spacing(1),
  },
  fieldset: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: 0,
    margin: 0,
    border: 'none',
    width: '100%',
  },
}));

const Form = forwardRef(({
                children,
                loading = false,
                onSubmit = noop,
                onReset = noop,
                ...rest
              }, ref) => {
  const classes = useStyles();

  return (
    <form className={classes.root} onSubmit={onSubmit} onReset={onReset} ref={ref} {...rest}>
      <Box style={{visibility: loading ? 'visible' : 'hidden'}} className={classes.progress}>
        <LinearProgress/>
      </Box>
      <fieldset className={classes.fieldset} disabled={loading}>
        {children}
      </fieldset>
    </form>
  );
});

Form.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};

export default Form;
