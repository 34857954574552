import { DASHBOARD_FILTER_CHANGE } from '../../actions';

const initialState = {
  filter: '',
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_FILTER_CHANGE:
      return reduceFilterChange(state, action);
    default:
      return state;
  }
};

export default dashboardReducer;

const reduceFilterChange = (state, action) => {
  const {value} = action.payload;
  return {
    ...state,
    filter: value,
  };
};
