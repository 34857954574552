import { Box, CircularProgress, Typography } from '@abdt/ornament';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

const Initialization = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography component="div" variant="h1">
        Пожалуйста, подождите...
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default Initialization;
