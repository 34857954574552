import { ThemeLayout } from '@abdt/ornament';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import ruLocale from 'date-fns/locale/ru';
import store from './store';
import theme from './theme';
import Main from './components/Main';
import '@abdt/fonts';

function App() {
  return (
    <Provider store={store}>
      <ThemeLayout theme={theme}>
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={ruLocale}>
          <Main/>
        </LocalizationProvider>
      </ThemeLayout>
    </Provider>
  );
}

export default App;
