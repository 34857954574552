import { LOGIN_FORM_CHANGE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL } from '../../actions';

const initialState = {
  login: '',
  password: '',
  loading: false,
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FORM_CHANGE:
      return reduceChange(state, action);
    case LOGIN_REQUEST:
      return reduceRequest(state, action);
    case LOGIN_SUCCESS:
      return reduceSuccess(state, action);
    case LOGIN_FAIL:
      return reduceFail(state, action);
    default:
      return state;
  }
};

export default loginReducer;

const reduceChange = (state, action) => {
  const {payload: {name, value}} = action;
  return {
    ...state,
    [name]: value,
  };
};

const reduceRequest = (state, action) => {
  return {
    ...state,
    loading: true,
    error: null,
  };
};

const reduceSuccess = (state, action) => {
  return {
    ...initialState,
  };
};

const reduceFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  };
};
