import client from '../../http';
import anonymous from '../../security/anonymous';
import { clearStorage, tokenFromCookie } from '../../storage';
import {
  AUTHENTICATE,
  DASHBOARD_FILTER_CHANGE,
  DATA_EDIT,
  DATA_FAIL,
  DATA_FORM_CHANGE,
  DATA_FORM_DATA_CHANGE, DATA_FORM_DATA_COLLECTION_ADD, DATA_FORM_DATA_COLLECTION_REMOVE,
  DATA_FORM_DISABLE,
  DATA_FORM_ENABLE,
  DATA_REMOVE_FAIL,
  DATA_REMOVE_REQUEST,
  DATA_REMOVE_SUCCESS,
  DATA_REQUEST,
  DATA_SAVE_COMPLETE,
  DATA_SAVE_FAIL,
  DATA_SAVE_REQUEST,
  DATA_SAVE_SUCCESS,
  DATA_SILENT_SUCCESS,
  DATA_SUCCESS,
  DATA_UPDATE_FAIL,
  DATA_UPDATE_REQUEST,
  DATA_UPDATE_SUCCESS,
  INITIALIZED,
  LOGIN_FAIL,
  LOGIN_FORM_CHANGE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  RESET,
} from './types';
import { collectionFormSelector, loginFormSelector } from '../hooks';

export const initialized = () => {
  return {
    type: INITIALIZED,
    payload: {},
  };
};

export const initialize = () => async (dispatch, getState) => {
  try {
    const token = tokenFromCookie();
    if (!token) {
      return;
    }

    const {data: user} = await client.get(`/me`);

    if (user.id === anonymous.id) {
      clearStorage();
      return;
    }

    dispatch(authenticate(token, user));
  } catch (e) {
    console.error(e);
    // FIXME: add retry functionality
    clearStorage();
  } finally {
    dispatch(initialized());
  }
};

export const authenticate = (token, user) => {
  return {
    type: AUTHENTICATE,
    payload: {
      token,
      user,
    },
  };
};

export const reset = () => {
  return {
    type: RESET,
    payload: {},
  };
};

export const loginFormChange = (name, value) => {
  return {
    type: LOGIN_FORM_CHANGE,
    payload: {
      name,
      value,
    },
  };
};

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
    payload: {},
  };
};

export const loginSuccess = (token, user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {},
  };
};

export const loginFail = (error) => {
  return {
    type: LOGIN_FAIL,
    payload: {
      error,
    },
  };
};

export const login = () => async (dispatch, getState) => {
  try {
    const {login, password} = loginFormSelector(getState());
    dispatch(loginRequest());

    const {data: {token}} = await client.post(`/auth`, {
      login,
      password,
    });
    const {data: user} = await client.get(`/me`);

    dispatch(loginSuccess());
    dispatch(authenticate(token, user));
  } catch (e) {
    console.error(e);
    dispatch(loginFail(e));
  }
};

export const logout = () => dispatch => {
  clearStorage();
  dispatch(reset());
};

export const dataLoadRequest = () => {
  return {
    type: DATA_REQUEST,
    payload: {},
  };
};

export const dataLoadSuccess = payload => {
  return {
    type: DATA_SUCCESS,
    payload,
  };
};

export const dataLoadSilentSuccess = payload => {
  return {
    type: DATA_SILENT_SUCCESS,
    payload,
  };
};

export const dataLoadFail = (error) => {
  return {
    type: DATA_FAIL,
    payload: {
      error,
    },
  };
};

export const loadData = () => async (dispatch, getState) => {
  try {
    dispatch(dataLoadRequest());

    const {data} = await client.get(`/data`);
    dispatch(dataLoadSuccess(data));
  } catch (e) {
    console.error(e);
    dispatch(dataLoadFail(e));
  }
};

export const loadDataSilent = () => async (dispatch, getState) => {
  try {
    const {data} = await client.get(`/data`);
    dispatch(dataLoadSilentSuccess(data));
  } catch (e) {
    console.error(e);
  }
};

export const dataEdit = (id, collection) => {
  return {
    type: DATA_EDIT,
    payload: {
      id,
      collection,
    },
  };
};

export const dataFormChange = (collection, property, value) => {
  return {
    type: DATA_FORM_CHANGE,
    payload: {
      collection,
      property,
      value,
    },
  };
};

export const dataFormDataChange = (collection, property, value) => {
  return {
    type: DATA_FORM_DATA_CHANGE,
    payload: {
      collection,
      property,
      value,
    },
  };
};

export const dataFormDataCollectionAdd = (collection, property, value) => {
  return {
    type: DATA_FORM_DATA_COLLECTION_ADD,
    payload: {
      collection,
      property,
      value,
    },
  };
};

export const dataFormDataCollectionRemove = (collection, property, value) => {
  return {
    type: DATA_FORM_DATA_COLLECTION_REMOVE,
    payload: {
      collection,
      property,
      value,
    },
  };
};

export const dataFormDisable = (collection) => {
  return {
    type: DATA_FORM_DISABLE,
    payload: {
      collection,
    },
  };
};

export const dataFormEnable = (collection) => {
  return {
    type: DATA_FORM_ENABLE,
    payload: {
      collection,
    },
  };
};

export const dataSaveRequest = (collection) => {
  return {
    type: DATA_SAVE_REQUEST,
    payload: {
      collection,
    },
  };
};

export const dataSaveSuccess = (item, collection) => {
  return {
    type: DATA_SAVE_SUCCESS,
    payload: {
      item,
      collection,
    },
  };
};

export const dataSaveFail = (error, collection) => {
  return {
    type: DATA_SAVE_FAIL,
    payload: {
      error,
      collection,
    },
  };
};

export const dataSaveComplete = (collection) => {
  return {
    type: DATA_SAVE_COMPLETE,
    payload: {
      collection,
    },
  };
};

export const dataSave = (collection) => async (dispatch, getState) => {
  try {
    const {item} = collectionFormSelector(collection)(getState());
    dispatch(dataSaveRequest(collection));

    const {data} = await client.post(`/data`, {...item, collection});
    dispatch(dataSaveSuccess(data, collection));
  } catch (e) {
    console.error(e);
    dispatch(dataSaveFail(e, collection));
  }
};

export const dataUpdateRequest = (id, collection) => {
  return {
    type: DATA_UPDATE_REQUEST,
    payload: {
      id,
      collection,
    },
  };
};

export const dataUpdateSuccess = (item, collection) => {
  return {
    type: DATA_UPDATE_SUCCESS,
    payload: {
      item,
      collection,
    },
  };
};

export const dataUpdateFail = (error, collection) => {
  return {
    type: DATA_UPDATE_FAIL,
    payload: {
      error,
      collection,
    },
  };
};

export const dataUpdate = (id, collection, property, value) => async (dispatch, getState) => {
  try {
    dispatch(dataUpdateRequest(id, collection));

    const {data} = await client.patch(`/${collection}/${id}`, {property, value});
    dispatch(dataUpdateSuccess(data, collection));
  } catch (e) {
    console.error(e);
    dispatch(dataUpdateFail(e, collection));
  }
};

export const dataRemoveRequest = (id, collection) => {
  return {
    type: DATA_REMOVE_REQUEST,
    payload: {
      id,
      collection,
    },
  };
};

export const dataRemoveSuccess = collection => {
  return {
    type: DATA_REMOVE_SUCCESS,
    payload: {
      collection,
    },
  };
};

export const dataRemoveFail = (error, collection) => {
  return {
    type: DATA_REMOVE_FAIL,
    payload: {
      error,
      collection,
    },
  };
};

export const dataRemove = (id, collection) => async (dispatch, getState) => {
  try {
    dispatch(dataRemoveRequest(id, collection));

    await client.delete(`/data/${collection}/${id}`);
    dispatch(dataRemoveSuccess(collection));
    dispatch(loadData());
  } catch (e) {
    console.error(e);
    dispatch(dataRemoveFail(e, collection));
  }
};

export const dashboardFilterChange = value => {
  return {
    type: DASHBOARD_FILTER_CHANGE,
    payload: {
      value,
    },
  };
};
