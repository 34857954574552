import React from 'react';
import PropTypes from 'prop-types';
import icon from './icon.svg';

const Remove = () => {
  return (
    <img src={icon}/>
  );
};

Remove.propTypes = {
};

export default Remove;
