import anonymous from '../../../security/anonymous';
import { AUTHENTICATE } from '../../actions';

const initialState = {
  token: null,
  user: anonymous,
  agreement: {
    loading: false,
    error: null,
  },
};

const tokenAndUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return reduceLogin(state, action);
    default:
      return state;
  }
};

export default tokenAndUserReducer;

const reduceLogin = (state, action) => {
  const {token, user} = action.payload;
  return {
    ...state,
    token,
    user,
  };
};
