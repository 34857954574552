import { createMuiTheme } from '@material-ui/core/styles';
import { themes } from '@abdt/ornament';

const primaryColor = '#6179FF';

const theme = createMuiTheme(themes.ecp, {
  colors: {
    primary: {
      100: primaryColor,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryColor,
    },
  },
  spacing: factor => factor * 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    // FIXME: bad hack
    MuiMenuItem: {
      root: {
        '&:active': {
          backgroundColor: 'transparent !important',
          color: `unset !important`,
        },
      },
    },
  }
});

export default theme;
