import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Box, Container, Grid, TextField, Typography, } from '@abdt/ornament';
import Form from '../../components/Form';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useLoginForm } from '../../store/hooks';
import { login, loginFormChange, } from '../../store/actions';
import Error from '../../components/Error';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('md')]: {},
    [theme.breakpoints.up('lg')]: {},
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(9),
    minHeight: '100vh',
  },
  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(9),
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  form: {
    textAlign: 'initial',
  },
}));

const LoginPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = useLoginForm();
    const formRef = useRef();

    const formChangeFn = loginFormChange;
    const formSubmitFn = login;

    const handleFormChange = evt => {
      const {name, value} = evt.target;
      dispatch(formChangeFn(name, value));
    };

    const handleFormSubmit = evt => {
      evt.preventDefault();
      dispatch(formSubmitFn());
    };

    return (
      <>
        <Box className={classes.root}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6} lg={4}>
                <Box className={classes.logo}>
                  <Typography variant="h1">
                    FAQ Plus
                  </Typography>
                </Box>
                <Box className={classes.form}>
                  <Form ref={formRef} onSubmit={handleFormSubmit} loading={form.loading}>
                    <TextField label="Логин" name="login" value={form.login} onChange={handleFormChange}
                               fullWidth
                               required/>
                    <TextField label="Пароль" type="password" name="password" value={form.password}
                               onChange={handleFormChange} fullWidth required/>
                    {form.error && <Error error={form.error}/>}
                    <PrimaryButton type="submit">Войти</PrimaryButton>
                  </Form>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
;

export default LoginPage;
