import { LinearProgress, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Box, Grid, TextField, Typography } from '@abdt/ornament';
import EditNavbar from '../../components/navbars/EditNavbar';
import { useEffect } from 'react';
import Error from '../../components/Error';
import { useMe, useSiteForm, useSites } from '../../store/hooks';
import { dataEdit, dataFormChange, dataFormDataChange, dataSave, dataSaveComplete } from '../../store/actions';
import Form from '../../components/Form';
import routes from '../../routes';
import { v4 } from 'uuid';
import { SITES_COLLECTION } from '../../store/collections';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  row: {
    marginBottom: theme.spacing(1),
  }
}));

const SiteEditPage = (props) => {
  const classes = useStyles();
  const me = useMe();
  const {siteId: id} = useParams();
  const sites = useSites();
  const form = useSiteForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const name = form?.item?.isNew ? 'Добавить сайт' : 'Изменить сайт';
  const collection = SITES_COLLECTION;

  useEffect(() => {
    if (form.saved) {
      dispatch(dataSaveComplete(collection));
      history.goBack();
      return;
    }

    if (form.loading || form.error) {
      return;
    }

    if (id === '00000000-00000000-00000000-00000000') {
      const generated = v4();
      dispatch(dataFormChange(collection, 'id', generated));
      history.replace(routes.siteEdit.make(generated))
      return;
    }

    if (id !== '00000000-00000000-00000000-00000000') {
      dispatch(dataEdit(id, collection));
    }
  }, [dispatch, id, form.saved, form.loading, form.error, collection]);

  const handleFormChange = evt => {
    const {name, value} = evt.target;
    dispatch(dataFormChange(collection, name, value));
  };

  const handleFormDataChange = evt => {
    const {name, value} = evt.target;
    dispatch(dataFormDataChange(collection, name, value));
  };

  const handleSave = () => {
    handleSubmit(new Event('submit'));
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    dispatch(dataSave(collection));
  };

  return (
    <>
      <EditNavbar name={name} onSave={handleSave}/>
      <Box component="main" className={classes.content}>
        <Box visibility={form.loading || sites.loading ? 'visible' : 'hidden'}>
          <LinearProgress/>
        </Box>
        <Box visibility={form.error || sites.error ? 'visible' : 'hidden'}>
          <Error error={form.error || sites.error}/>
        </Box>
        {form.item && <>
          <Form onSubmit={handleSubmit} loading={form.loading}>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Сайт</Typography>
                <TextField label="Домен" name="domain" value={form.item?.data?.domain ?? ''} margin="normal" fullWidth
                           required
                           onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
          </Form>
        </>}
      </Box>
    </>
  );
};

export default SiteEditPage;
