import { LinearProgress, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Box, Chip, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@abdt/ornament';
import EditNavbar from '../../components/navbars/EditNavbar';
import { Fragment, useEffect, useMemo } from 'react';
import Error from '../../components/Error';
import { useItemForm, useItems, useMe, useSites } from '../../store/hooks';
import {
  dataEdit,
  dataFormChange,
  dataFormDataChange,
  dataFormDataCollectionAdd,
  dataFormDataCollectionRemove,
  dataSave,
  dataSaveComplete
} from '../../store/actions';
import Form from '../../components/Form';
import routes from '../../routes';
import { v4 } from 'uuid';
import { ITEMS_COLLECTION } from '../../store/collections';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  row: {
    marginBottom: theme.spacing(1),
  }
}));

const ItemEditPage = (props) => {
  const classes = useStyles();
  const me = useMe();
  const {siteId, itemId: id} = useParams();
  const sites = useSites();
  const items = useItems();
  const form = useItemForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const name = form?.item?.isNew ? 'Добавить вопрос' : 'Изменить вопрос';
  const collection = ITEMS_COLLECTION;

  const othersLinked = useMemo(() => {
    return (form?.item?.data?.others ?? []).map(o => ({
      id: o,
      item: items?.items?.find(i => i.id === o),
    }));
  }, [form, items]);

  const others = useMemo(() => {
    return items?.items?.filter(i => i?.data?.siteId === siteId && i.id !== id && !othersLinked.some(o => o.id === i.id));
  }, [siteId, id, items, othersLinked]);

  useEffect(() => {
    if (form.saved) {
      dispatch(dataSaveComplete(collection));
      history.goBack();
      return;
    }

    if (form.loading || form.error) {
      return;
    }

    if (id === '00000000-00000000-00000000-00000000') {
      const generated = v4();
      dispatch(dataFormChange(collection, 'id', generated));
      history.replace(routes.itemEdit.make(siteId, generated))
      return;
    }

    if (id !== '00000000-00000000-00000000-00000000') {
      dispatch(dataEdit(id, collection));
      dispatch(dataFormDataChange(collection, 'siteId', siteId));
    }
  }, [dispatch, id, siteId, form.saved, form.loading, form.error, collection]);

  const handleFormChange = evt => {
    const {name, value} = evt.target;
    dispatch(dataFormChange(collection, name, value));
  };

  const handleFormDataChange = evt => {
    const {name, value} = evt.target;
    dispatch(dataFormDataChange(collection, name, value));
  };

  const handleOtherSelect = evt => {
    const {name, value} = evt.target;
    dispatch(dataFormDataCollectionAdd(collection, name, value));
  };

  const handleOtherDelete = (name, value) => {
    dispatch(dataFormDataCollectionRemove(collection, name, value));
  };

  const handleSave = () => {
    handleSubmit(new Event('submit'));
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    dispatch(dataSave(collection));
  };

  return (
    <>
      <EditNavbar name={name} onSave={handleSave}/>
      <Box component="main" className={classes.content}>
        <Box visibility={form.loading || sites.loading || items.loading ? 'visible' : 'hidden'}>
          <LinearProgress/>
        </Box>
        <Box visibility={form.error || sites.error || items.error ? 'visible' : 'hidden'}>
          <Error error={form.error || sites.error || items.error}/>
        </Box>
        {form.item && <>
          <Form onSubmit={handleSubmit} loading={form.loading}>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Вопрос</Typography>
                <TextField label="Вопрос" name="name" value={form.item?.data?.name ?? ''} margin="normal" fullWidth
                           required onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Позиция в общем списке</Typography>
                <TextField label="Позиция в общем списке (-1 для скрытия)" name="positionInCommon"
                           value={form.item?.data?.positionInCommon ?? ''} margin="normal" fullWidth required
                           type="number"
                           onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Позиция в списке "Актуальное"</Typography>
                <TextField label="Позиция в списке &quot;Актуальное&quot; (-1 для скрытия)" name="positionInActual"
                           value={form.item?.data?.positionInActual ?? ''} margin="normal" fullWidth required
                           type="number"
                           onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Другие вопросы по данной теме</Typography>
                <FormControl margin="normal" size="small" fullWidth>
                  <Select
                    labelId="otherId"
                    name="others"
                    value=""
                    disabled={form.loading}
                    onChange={handleOtherSelect}
                  >
                    <MenuItem value="">Выберите из списка для добавления</MenuItem>
                    {others?.map(i => <MenuItem key={i.id} value={i.id}>
                      {i.data?.name}
                    </MenuItem>)}
                  </Select>
                </FormControl>
                {othersLinked?.map(o => <Fragment key={o.id}>
                  <Chip label={o.item?.data?.name} onDelete={() => handleOtherDelete('others', o.id)}/>
                </Fragment>)
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Краткий текст</Typography>
                <TextField label="Краткий текст" name="textShort" value={form.item?.data?.textShort ?? ''}
                           margin="normal" multiline rows={3} fullWidth required onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8} lg={6}>
                <Typography variant="h4" component="h1">Полный текст (HTML)</Typography>
                <TextField label="Полный текст (HTML)" name="textFull" value={form.item?.data?.textFull ?? ''}
                           margin="normal" multiline rows={25} fullWidth required onChange={handleFormDataChange}/>
              </Grid>
            </Grid>
          </Form>
        </>}
      </Box>
    </>
  );
};

export default ItemEditPage;
