import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../../routes';
import LoginPage from '../../../pages/LoginPage';

const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={routes.signin.pattern} exact={true}>
        <LoginPage/>
      </Route>
      <Redirect to={routes.signin.pattern}/>
    </Switch>
  );
};

export default UnauthenticatedRoutes;
