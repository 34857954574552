import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../../routes';
import DashboardPage from '../../../pages/DashboardPage';
import Sidebar from '../../Sidebar';
import { Box } from '@abdt/ornament';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import SiteEditPage from '../../../pages/SiteEditPage';
import ItemsPage from '../../../pages/ItemsPage';
import ItemEditPage from '../../../pages/ItemEditPage';

const useStyles = makeStyles(theme => ({
  page: {
    marginLeft: 80,
  },
}));

const AuthenticatedRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.page}>
        <Switch>
          <Route path={routes.dashboard.pattern} exact={true}>
            <DashboardPage/>
          </Route>
          <Route path={routes.siteEdit.pattern} exact={true}>
            <SiteEditPage/>
          </Route>
          <Route path={routes.items.pattern} exact={true}>
            <ItemsPage/>
          </Route>
          <Route path={routes.itemEdit.pattern} exact={true}>
            <ItemEditPage/>
          </Route>
          {/*<Route path={routes.vehicles.pattern} exact={true}>*/}
          {/*  <VehicleListPage/>*/}
          {/*</Route>*/}
          {/*<Route path={routes.vehicleEdit.pattern} exact={true}>*/}
          {/*  <VehicleEditPage/>*/}
          {/*</Route>*/}
          {/*<Route path={routes.vehicleVideo.pattern} exact={true}>*/}
          {/*  <VehicleVideoPage/>*/}
          {/*</Route>*/}
          {/*{(me.roles.includes(ROLE_ADMIN) || me.parentId === null) && <Route path={routes.users.pattern} exact={true}>*/}
          {/*  <UserListPage/>*/}
          {/*</Route>*/}
          {/*}*/}
          {/*{(me.roles.includes(ROLE_ADMIN) || me.parentId === null) && <Route path={routes.userEdit.pattern} exact={true}>*/}
          {/*  <UserEditPage/>*/}
          {/*</Route>*/}
          {/*}*/}
          {/*{(me.roles.includes(ROLE_ADMIN) || me.parentId === null) && <Route path={routes.userDevices.pattern} exact={true}>*/}
          {/*  <UserDevicesPage/>*/}
          {/*</Route>*/}
          {/*}*/}
          {/*<Route path={routes.report.pattern} exact={true}>*/}
          {/*  <ReportPage/>*/}
          {/*</Route>*/}
          {/*<Route path={routes.jobs.pattern} exact>*/}
          {/*  <JobsPage />*/}
          {/*</Route>*/}
          {/*<Route path={routes.jobEdit.pattern} exact>*/}
          {/*  <JobEditPage/>*/}
          {/*</Route>*/}
          {/*<Route path={routes.notifications.pattern} exact>*/}
          {/*  <NotificationsPage />*/}
          {/*</Route>*/}
          {/*<Route path={routes.promocodes.pattern} exact>*/}
          {/*  <PromocodesPage />*/}
          {/*</Route>*/}
          <Redirect to={routes.dashboard.pattern}/>
        </Switch>
      </Box>
      <Sidebar/>
    </>
  );
};

export default AuthenticatedRoutes;
