import noop from '../../../utils/noop';
import PropTypes from 'prop-types';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@abdt/ornament';
import { ArrowBack, Cancel, Save } from '@material-ui/icons';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';
import TextButton from '../../buttons/TextButton';
import PrimaryButton from '../../buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
  name: {
    textOverflow: 'ellipsis',
  },
}));

const EditNavbar = ({name, onSave = noop}) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handleSave = () => {
    onSave();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item>
                <IconButton onClick={handleBack}>
                  <ArrowBack/>
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h4" component="span" className={classes.name}>
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {match && <>
              <TextButton onClick={handleBack}>Отмена</TextButton>
              <PrimaryButton onClick={handleSave}>Сохранить</PrimaryButton>
            </>}
            {!match && <>
              <IconButton color="primary" onClick={handleBack}>
                <Cancel/>
              </IconButton>
              <IconButton color="primary" onClick={handleSave}>
                <Save/>
              </IconButton>
            </>}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

EditNavbar.propTypes = {
  name: PropTypes.string,
  onSave: PropTypes.func,
};

export default EditNavbar;
